import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteDeleteSegment,
  getDeleteSegments,
  clearErrorDeleteSegments,
  setCurrentDeleteSegment,
  clearCurrentDeleteSegment,
  setBypassIoDeleteSegments
} from "../actions/delete_segments";

import { getLanguages } from "../actions/related";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import DeleteSegmentForm from "./DeleteSegmentForm";
import AddDeleteSegmentBtn from "./AddDeleteSegmentBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Icon, Breadcrumbs, Link, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import EnhancedTable from "../shared/components/UIElements/EnhancedTable";
import { Helmet } from "react-helmet";

const DeleteSegments = ({
  delete_segments,
  loading,
  error,
  current,
  bypassIo,
  clearErrorDeleteSegments,
  setCurrentDeleteSegment,
  clearCurrentDeleteSegment,
  setBypassIoDeleteSegments,
  deleteDeleteSegment,
  getDeleteSegments,
  dispatch,
  getLanguages,
  allLanguages
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if ((!delete_segments || delete_segments.length === 0) && !error) getDeleteSegments();
    if (!loading) {
      subscribeTo("delete_segmentsChanged", (err, data) => {
        if (!bypassIo) getDeleteSegments();
        else {
          getDeleteSegments();
          setBypassIoDeleteSegments(false);
        }
      });
      return () => {
        subscribeTo("delete_segmentsChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, delete_segment) => {
    confirm({
      description: `Are you sure you want to delete the text "${delete_segment}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm"
    }).then(() => deleteDeleteSegment(delete_segment));
  };

  const onClickEdit = (lang) => {
    setCurrentDeleteSegment(lang);
  };

  const columns = [
    {
      id: "position",
      numeric: false,
      disablePadding: false,
      label: "Pos",
      width: "5%"
    },
    {
      id: "rules",
      numeric: false,
      disablePadding: false,
      label: "Rules",
      noSelect: true,
      transform: function (row) {
        return row.rules ? (
          <Grid container direction="column">
            <Grid item container direction="row">
              {row.rules
                .sort((a, b) => a.localeCompare(b))
                .map((t, pos) => ({ t, pos }))
                /* .filter(
                    (t) =>
                      t.pos > ((i - 1) * row.triggers.split(";").length) / 6 &&
                      t.pos <= (i * row.triggers.split(";").length) / 6
                  )*/
                .map((t, pos) => (
                  <Grid item md="2" direction="row">
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => onClickDelete(confirm, t.t)}
                      style={{ color: "gray", textDecoration: "unset" }}
                    >
                      x
                    </a>{" "}
                    {t.t}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          "-"
        );
      }
    }
  ];

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Segments to Delete - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorDeleteSegments} />
      <React.Fragment>
        <CssBaseline />
        <DeleteSegmentForm current={current} clearCurrentDeleteSegment={clearCurrentDeleteSegment} />
        <Container maxWidth="xl">
          {delete_segments.length !== 0 && allLanguages.length !== 0 && (
            <EnhancedTable
              rows={delete_segments}
              columns={columns}
              options={{
                title: "Segments to Delete Manager",
                multiple_select: false,
                hide_pagination: true
              }}
              actions={null}
            ></EnhancedTable>
          )}
          <AddDeleteSegmentBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  }
});

const mapStateToProps = (state) => ({
  delete_segments: state.delete_segments.all,
  loading: state.delete_segments.loading,
  error: state.delete_segments.error,
  current: state.delete_segments.current,
  bypassIo: state.delete_segments.bypassIo,
  allLanguages: state.related.languages
});

const mapDispatchToProps = (dispatch) => ({
  deleteDeleteSegment: (id) => dispatch(deleteDeleteSegment(id)),
  getDeleteSegments: () => dispatch(getDeleteSegments()),
  clearErrorDeleteSegments: () => dispatch(clearErrorDeleteSegments()),
  setCurrentDeleteSegment: (delete_segment) => dispatch(setCurrentDeleteSegment(delete_segment)),
  clearCurrentDeleteSegment: () => dispatch(clearCurrentDeleteSegment()),
  setBypassIoDeleteSegments: (bypass) => dispatch(setBypassIoDeleteSegments(bypass)),
  getLanguages: () => dispatch(getLanguages())
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegments);
