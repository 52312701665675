export const getDomain = (url) => {
  const { parseDomain, fromUrl } = require("parse-domain");
  let search;
  if (
    url.indexOf("facebook.com") === -1 &&
    url.indexOf("twitter.com") === -1 &&
    url.indexOf("pinterest.com") === -1 &&
    url.indexOf("issuu.com") === -1
  )
    search = fromUrl(url)
      .replace("www.", "")
      .replace(/^(m\.)/gi, "")
      .replace(/^(mobile\.)/gi, "");
  else {
    let searchTmp = url.replace("http://", "").replace("https://", "");
    search = searchTmp.split("/")[0] + "/" + searchTmp.split("/")[1];
    search = search.replace("www.", "");
  }

  return search;
};

export const isRelevantSource = (source) => {
  if (source.is_relevant) return true;
  if ([3, 4, 5, 8, 9, 10, 11].indexOf(source.source_type_id) !== -1)
    return true;

  return false;
};

export const uncaps = (str) => {
  const terms = [
    "GLBT",
    "LBQ",
    "LBQ+",
    "LBT",
    "LGBT",
    "LGBT+",
    "LGBTI",
    "LGBTI+",
    "LGBTIQ",
    "LGBTIQ+",
    "LGBTIQA",
    "LGBTIQA+",
    "LGBTIQAPD",
    "LGBTIQAPD+",
    "LGBTQ",
    "LGBTQ+",
    "LGBTQ2S",
    "LGBTQ2S+",
    "LGBTQI",
    "LGBTQI",
    "LGBTQIA",
    "LGTB",
    "LGTB+",
    "LGTBI",
    "LGTBI+",
  ];
  if (str && str === str.toUpperCase()) {
    let allStr = str.split(" ");
    let newStr = "";
    let i = 0;
    for (let strTmp of allStr) {
      if (terms.indexOf(strTmp) !== -1) {
        newStr += " " + strTmp;
      } else {
        strTmp = strTmp.toLowerCase();
        if (i === 0) {
          newStr += " " + strTmp.charAt(0).toUpperCase() + strTmp.slice(1);
        } else {
          newStr += " " + strTmp;
        }
        i++;
      }
    }
    return newStr;
  } else {
    return str;
  }
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https://|http://|ftp://)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const isCrimCountry = (article, con_jur) => {
  const crimCountries = [
    "AF",
    "DZ",
    "AG",
    "BD",
    "BB",
    "BN",
    "BI",
    "CM",
    "TD",
    "KM",
    "DM",
    "EG",
    "ER",
    "SZ",
    "ET",
    "CD",
    "GM",
    "GH",
    "GD",
    "GN",
    "GY",
    "ID",
    "IR",
    "IQ",
    "JM",
    "KE",
    "KI",
    "KW",
    "LB",
    "LR",
    "LY",
    "MW",
    "MY",
    "MV",
    "MR",
    "MU",
    "MA",
    "MM",
    "NA",
    "NG",
    "OM",
    "PK",
    "PG",
    "QA",
    "KN",
    "LC",
    "VC",
    "WS",
    "SA",
    "SN",
    "SL",
    "SG",
    "SB",
    "SO",
    "SS",
    "LK",
    "SD",
    "SY",
    "TZ",
    "TG",
    "TO",
    "TN",
    "TM",
    "TV",
    "UG",
    "AE",
    "UZ",
    "YE",
    "ZM",
    "ZW",
    "PS",
    "CK",
  ];

  if (
    (con_jur && crimCountries.indexOf(con_jur) !== -1) ||
    (article.jurisdictions &&
      article.jurisdictions.length &&
      article.jurisdictions.some(
        (j) => crimCountries.indexOf(j.jurisdiction.country_code) !== -1
      ))
  )
    return true;
  return false;
};

export const roleNames = {
  1: "User",
  2: "User Plus",
  3: "Admin",
};

export const CRIM_ENFORCEMENT_TAG = 161;

export const jurWarnings = {
  "UN-071": (
    <>
      Attention! You are about to approve an entry assigned to Guinea (GN), the
      West African country. This JUR is very rare and usually gets confused with
      entries from Papua New Guinea, or is wrongly triggered by expressions that
      use the term "guinea" in unrelated ways.
      <br />
      <br />
      Are you sure this entry is on the African country of Guinea?
    </>
  ),
  "UN-135": (
    <>
      Attention! You are about to approve an entry assigned to Papua New Guinea
      (PG), a country in Oceania. This JUR is very rare and usually gets
      confused with entries from Guinea (Africa), or is wrongly triggered by
      expressions that use the term "guinea" in unrelated ways.
      <br />
      <br />
      Are you sure this entry is on the country in Oceania, Papua New Guinea?
    </>
  ),
  "UN-149": (
    <>
      Attention! You are about to approve an entry assigned to San Marino. This
      JUR is very rare and local media in San Marino report heavily on events
      occurring in Italy.
      <br />
      <br />
      Are you sure the entry is on San Marino?
    </>
  ),
  "DT-26": (
    <>
      Attention! You are about to approve an entry assigned to the UK overseas
      territory of Montserrat (MS). This JUR is extremely rare and the Spanish
      female name Montserrat usually tricks the ASPU into believing the entry is
      from the Caribbean Island.
      <br />
      <br />
      Are you sure the entry is on Montserrat (UK)?
    </>
  ),
  "UN-069": (
    <>
      Attention! You are about to approve an entry assigned to the Caribbean
      nation of Grenada (GD). This JUR is extremely rare and the Spanish city of
      Granada usually tricks the ASPU into believing the entry is from the
      Caribbean nation.
      <br />
      <br />
      Are you sure the entry is on Grenada and not Spain?
    </>
  ),
  "UN-034": (
    <>
      Attention! You are about to approve an entry assigned to the central
      African nation of Chad (TD). This JUR is extremely rare and the English
      name "Chad" usually tricks the ASPU into believing the entry is from the
      African nation.
      <br />
      <br />
      Are you sure the entry is on Chad?
    </>
  ),
  "UN-032": (
    <>
      Attention! You are about to approve an entry assigned to Cape Verde (TD).
      This JUR is quite rare and news from Brazil usually get wrongly labelled
      as coming from this African nation.
      <br />
      <br />
      Are you sure the entry is on Cape Verde?
    </>
  ),
};
