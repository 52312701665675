import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import {
  addSource,
  clearCurrentSource,
  clearErrorSources,
  deleteSource,
  getSources,
  setCurrentSource,
} from "../actions/sources";

import { addArticle, setOpenArticle, updateArticle } from "../actions/articles";

import {
  getAlertSubaccounts,
  getJurisdictions,
  getLanguages,
  getSourceStatuses,
  getSourceTypes,
  getTags,
} from "../actions/related";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import isURL from "validator/lib/isURL";

const emptyArticle = {
  original_header: "",
  translated_header: "",
  detected_language: null,
  original_url: "",
  digest_date: new Date().toISOString(),
  source: { id: null },
  jurisdictions: [],
  tags: [],
  original_description: "",
  original_url: "",
};

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const ArticleForm = ({
  current,
  open,
  setCurrentArticle,
  clearCurrentArticle,
  updateArticle,
  addArticle,
  loading,
  allLanguages,
  getLanguages,
  setOpenArticle,
  getAlertSubaccounts,
  alert_subaccounts,
  source_types,
  getSourceTypes,
  source_statuses,
  getSourceStatuses,
  allJurisdictions,
  getJurisdictions,
  allTags,
  getTags,
  sources,
  setSuccess,
}) => {
  const classes = useStyles();
  const [article, setArticle] = useState(emptyArticle);
  const [statusValue, setStatusValue] = React.useState("Active");
  const [typeValue, setTypeValue] = React.useState("Media Outlet");
  const [disableTrans, setDisableTrans] = React.useState(false);
  const [error, setError] = React.useState("");

  const {
    original_header,
    translated_header,
    detected_language,
    digest_date,
    jurisdictions,
    source_id,
    tags,
    original_description,
    original_url,
  } = article;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (!sources || !sources.length) getSources();
    if (!allTags || !allTags.length) getTags();
    if (!source_statuses || !source_statuses.length) getSourceStatuses();
    if (!allJurisdictions || !allJurisdictions.length) getJurisdictions();
    if (!alert_subaccounts || alert_subaccounts.length === 0)
      getAlertSubaccounts();
    if (current !== null) {
      console.log(article);
      setArticle({
        ...current,
        ...(current.jurisdictions && {
          jurisdictions: current.jurisdictions.map((j) => ({
            id: j.jurisdiction.id,
            name: j.jurisdiction.name,
          })),
        }),
        ...(current.tags && {
          tags: current.tags.map((j) => ({
            id: j.tag.id,
            tag: j.tag.tag,
          })),
        }),
      });
    } else {
      setArticle(emptyArticle);
    }
    if (current) {
      console.log(current.jurisdictions);
      console.log(current.tags);
    }
  }, [current, open]);

  function handleClose() {
    setOpenArticle(false);
    clearCurrentArticle();
  }
  function onSubmit(e) {
    e.preventDefault();
    if (
      !original_header ||
      !original_url ||
      !detected_language ||
      !digest_date ||
      !source_id ||
      (!current && !jurisdictions.length) ||
      (!current && !tags.length)
    ) {
      setError("Complete all required (*) fields");
      return;
    } else if (
      !isURL(original_url, {
        protocols: ["http", "https", "ftp"],
        require_tld: true,
        require_protocol: true,
        require_host: true,
        require_valid_protocol: true,
        allow_underscores: false,
        host_whitelist: false,
        host_blacklist: false,
        allow_trailing_dot: false,
        allow_protocol_relative_urls: false,
        disallow_auth: false,
      })
    ) {
      setError("Please enter a valid URL");
      return;
    }

    try {
      setDisableTrans(false);
      if (!current || !current.id) {
        addArticle(article)
          .then(() => {
            setSuccess({
              msg: "Article saved successfully",
              data: {
                action: "addarticle",
                article: current,
              },
            });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        updateArticle(article)
          .then(() => {
            setSuccess({
              msg: "Article saved successfully",
              data: {
                action: "editarticle",
                article: current,
              },
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } catch (error) {
      console.log(error);
    }
    clearCurrentArticle();
  }

  const onChange = (e, newVal = null) => {
    console.log(newVal);
    if (e === "jurisdiction") {
      setArticle({ ...article, jurisdiction_id: newVal ? newVal.id : "" });
    } else if (e === "language") {
      setArticle({
        ...article,
        detected_language: newVal.id,
      });
    } else if (e === "source") {
      setArticle({
        ...article,
        source_id: newVal.id,
      });
    } else if (e === "jurisdictions") {
      setArticle({
        ...article,
        jurisdictions: newVal,
      });
    } else if (e === "tags") {
      setArticle({
        ...article,
        tags: newVal,
      });
    } else {
      let val = e.target.value;
      if (e.target.name === "languages") {
        val = val.map((v) => {
          if (typeof v === "string")
            return {
              id: v,
              name: allLanguages.find((r) => r.id === v).name,
            };
          else return v;
        });
      }

      //if (e.target.name === "original_header") setDisableTrans(true);

      setArticle({ ...article, [e.target.name]: val });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {error && (
        <ErrorSnack error={{ msg: error }} clearError={() => setError("")} />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "60%", height: "60%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Entry to Dashboard"
            : current && !open
            ? "Edit Entry: " + current.original_header
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="original_url"
                  label="URL *"
                  value={original_url}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={10}>
                <TextField
                  margin="dense"
                  name="original_header"
                  inputProps={{ maxLength: 400 }}
                  label="Original Header *"
                  value={original_header}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              {current && current.id && (
                <Grid item xs={12} md={2}>
                  <div className={classes.text}>
                    <strong>ID:</strong>{" "}
                    <a href={"/dashboard/" + current.id}>{current.id}</a>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} md={9}>
                <TextField
                  margin="dense"
                  name="translated_header"
                  inputProps={{ maxLength: 400 }}
                  label="Translated Header (Leave empty to auto-translate)"
                  value={translated_header}
                  onChange={onChange}
                  disabled={disableTrans || detected_language === "en"}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              {current &&
                current.alert_subaccount_id &&
                alert_subaccounts &&
                alert_subaccounts.length && (
                  <Grid item xs={12} md={3}>
                    <div className={classes.text}>
                      <strong>Account:</strong>{" "}
                      {alert_subaccounts
                        .find((a) => a.id === current.alert_subaccount_id)
                        .email.replace("@gmail.com", "")}
                    </div>
                  </Grid>
                )}
              <Grid item xs={12} md={10}>
                <TextField
                  margin="dense"
                  name="original_description"
                  inputProps={{ maxLength: 512 }}
                  label="Tagline"
                  value={original_description}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                {allLanguages && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={allLanguages}
                    disableClearable={true}
                    classes={{
                      option: classes.option,
                    }}
                    size="small"
                    onChange={(e, newVal) => onChange("language", newVal)}
                    autoHighlight
                    defaultValue={allLanguages.find(
                      (lang) => lang.id === detected_language
                    )}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language *"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {sources && (
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: "100%" }}
                    options={sources}
                    disableClearable={true}
                    classes={{
                      option: classes.option,
                    }}
                    size="small"
                    onChange={(e, newVal) => onChange("source", newVal)}
                    autoHighlight
                    defaultValue={sources.find((s) => s.id === source_id)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Source *"
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </Grid>{" "}
              {(current === null || current.articles) && (
                <Grid item xs={12} md={6}>
                  {allJurisdictions && (
                    <Autocomplete
                      id="country-select-demo"
                      style={{ width: "100%" }}
                      options={allJurisdictions}
                      disableClearable={false}
                      clearOnBlur={true}
                      classes={{
                        option: classes.option,
                      }}
                      multiple
                      defaultValue={jurisdictions}
                      autoHighlight
                      size="small"
                      onChange={(e, newVal) =>
                        onChange("jurisdictions", newVal)
                      }
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <React.Fragment>
                          {true && (
                            <span>
                              <img
                                width="32px"
                                src={
                                  "/img/country_flags/" +
                                  option.country_code +
                                  "@3x.png"
                                }
                              />
                            </span>
                          )}{" "}
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Jurisdictions" + (!current ? " *" : "")}
                          variant="outlined"
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={2}>
                <TextField
                  id="digest_date"
                  label="Date *"
                  name="digest_date"
                  type="date"
                  defaultValue={digest_date && digest_date.split("T")[0]}
                  onChange={onChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {(current === null || current.articles) && (
                <Grid item xs={12} md={12}>
                  {allTags && (
                    <Autocomplete
                      id="country-select-demo"
                      style={{ width: "100%" }}
                      options={allTags}
                      disableClearable={false}
                      clearOnBlur={true}
                      classes={{
                        option: classes.option,
                      }}
                      multiple
                      defaultValue={tags}
                      autoHighlight
                      size="small"
                      onChange={(e, newVal) => onChange("tags", newVal)}
                      getOptionLabel={(option) => option.tag}
                      renderOption={(option) => (
                        <React.Fragment>{option.tag}</React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Tags" + (!current ? " *" : "")}
                          variant="outlined"
                          margin="dense"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
  text: {
    paddingTop: "18px",
    textAlign: "center",
  },
}));

const mapStateToProps = (state) => ({
  sources: state.sources.all,
  loading: state.articles.loading,
  error: state.articles.error,
  open: state.articles.open,
  allLanguages: state.related.languages,
  source_types: state.related.source_types,
  source_statuses: state.related.source_statuses,
  allJurisdictions: state.related.jurisdictions,
  allTags: state.related.tags,
  alert_subaccounts: state.related.alert_subaccounts,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSource: (id) => dispatch(deleteSource(id)),
  getSources: () => dispatch(getSources()),
  clearErrorSources: () => dispatch(clearErrorSources()),
  setCurrentSource: (source) => dispatch(setCurrentSource(source)),
  setOpenArticle: (should) => dispatch(setOpenArticle(should)),
  clearCurrentSource: () => dispatch(clearCurrentSource()),
  addSource: (source) => dispatch(addSource(source)),
  updateArticle: (article) => dispatch(updateArticle(article)),
  addArticle: (article) => dispatch(addArticle(article)),
  getLanguages: () => dispatch(getLanguages()),
  getSourceTypes: () => dispatch(getSourceTypes()),
  getTags: () => dispatch(getTags()),
  getSourceStatuses: () => dispatch(getSourceStatuses()),
  getJurisdictions: () => dispatch(getJurisdictions()),
  getAlertSubaccounts: () => dispatch(getAlertSubaccounts()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
