import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteDbUser,
  approveDbUser,
  rejectDbUser,
  getDbUsers,
  clearErrorDbUsers,
  setCurrentDbUser,
  clearCurrentDbUser,
  setBypassIoDbUsers,
} from "../actions/db_users";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import DbUserForm from "./DbUserForm";
import AddDbUserBtn from "./AddDbUserBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Icon, Breadcrumbs, Link, Grid, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import { Helmet } from "react-helmet";
import moment from "moment";
import { roleNames } from "../utils/functions";

const DbUsers = ({
  db_users,
  loading,
  error,
  current,
  bypassIo,
  clearErrorDbUsers,
  setCurrentDbUser,
  clearCurrentDbUser,
  setBypassIoDbUsers,
  deleteDbUser,
  approveDbUser,
  rejectDbUser,
  getDbUsers,
  dispatch,
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();
  const [userFilter, setUserFilter] = useState(0);
  const [sortBy, setSortBy] = useState({ by: "id", desc: true });

  useEffect(() => {
    if ((!db_users || db_users.length === 0) && !error) getDbUsers();
    if (!loading) {
      subscribeTo("db_usersChanged", (err, data) => {
        if (!bypassIo) getDbUsers();
        else {
          getDbUsers();
          setBypassIoDbUsers(false);
        }
      });
      return () => {
        subscribeTo("db_usersChanged", null);
      };
    }
  }, [loading, bypassIo]);

  const onClickDelete = (confirm, db_user) => {
    confirm({
      description: `Are you sure you want to delete the DB User User "${db_user.email}"?`,
      title: "DELETE USER",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => deleteDbUser(db_user.id));
  };

  const onClickApprove = (confirm, db_user) => {
    confirm({
      description: `Are you sure you want to approve the DB User User "${db_user.email}"?`,
      title: "APPROVE USER",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => approveDbUser(db_user.id));
  };

  const onClickReject = (confirm, db_user, noMail = false) => {
    confirm({
      description: `Are you sure you want to ${
        noMail ? "block" : "reject"
      } the DB User User "${db_user.email}"?${
        noMail
          ? " The user will not be notified of this change and will no longer be able to log in."
          : ""
      }`,
      title: noMail ? "BLOCK USER" : "REJECT USER",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => rejectDbUser(db_user.id, noMail));
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ILGA World Database User Panel - ASPU</title>
      </Helmet>
      <ErrorSnack error={error} clearError={clearErrorDbUsers} />
      <React.Fragment>
        <CssBaseline />
        <DbUserForm current={current} clearCurrentDbUser={clearCurrentDbUser} />
        <Container maxWidth="xl">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link
              color="textPrimary"
              style={{ fontSize: "24px" }}
              href="/db_users"
              aria-current="page"
            >
              ILGA World Database User Panel
            </Link>
          </Breadcrumbs>
          <Fragment>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12} style={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "0px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "white",
                    textTransform: "unset",
                    backgroundColor: "green",
                    ...(userFilter === 0 && { border: "2px solid black" }),
                  }}
                  onClick={(e) => {
                    setUserFilter(0);
                  }}
                >
                  Active (
                  {db_users.filter((u) => !u.disabled && !u.blocked).length})
                </Button>

                <Button
                  variant="contained"
                  style={{
                    marginLeft: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "white",
                    textTransform: "unset",
                    backgroundColor: "#d48900",
                    ...(userFilter === 3 && { border: "2px solid black" }),
                  }}
                  color={"green"}
                  onClick={(e) => {
                    setUserFilter(1);
                  }}
                >
                  Pending (
                  {db_users.filter((u) => u.disabled && !u.blocked).length})
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "white",
                    textTransform: "unset",
                    backgroundColor: "#800020",
                    ...(userFilter === 2 && { border: "2px solid black" }),
                  }}
                  onClick={(e) => {
                    setUserFilter(2);
                  }}
                >
                  Blocked ({db_users.filter((u) => u.blocked).length})
                </Button>
              </Grid>
            </Grid>
          </Fragment>
          {db_users.length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "pronoun", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Pronoun
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "firstname", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      First Name
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "lastname", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Last Name
                    </TableCell>
                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "email", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      E-mail
                    </TableCell>

                    <Fragment>
                      <TableCell
                        onClick={() =>
                          setSortBy({ by: "role_id", desc: !sortBy.desc })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Level
                      </TableCell>
                    </Fragment>

                    <Fragment>
                      <TableCell
                        onClick={() =>
                          setSortBy({ by: "cso_name", desc: !sortBy.desc })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Organisation Name
                      </TableCell>
                    </Fragment>

                    <TableCell
                      onClick={() =>
                        setSortBy({ by: "last_login", desc: !sortBy.desc })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      Last Login
                    </TableCell>

                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {db_users
                    .filter((u) => {
                      return (
                        (userFilter === 0 && !u.disabled && !u.blocked) ||
                        (userFilter === 1 && u.disabled && !u.blocked) ||
                        (userFilter === 2 && u.blocked)
                      );
                    })
                    .sort((a, b) => {
                      if (sortBy.desc) {
                        if (a[sortBy.by] < b[sortBy.by]) return 1;
                        else if (a[sortBy.by] > b[sortBy.by]) return -1;
                        else return 0;
                      } else {
                        if (a[sortBy.by] > b[sortBy.by]) return 1;
                        else if (a[sortBy.by] < b[sortBy.by]) return -1;
                        else return 0;
                      }
                    })
                    .map((row) => (
                      <TableRow
                        key={row.name}
                        style={{
                          ...(row.blocked && {
                            textDecoration: "line-through",
                          }),
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.pronoun}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firstname}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.lastname}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          <strong
                            style={{
                              ...(row.role_id === 1 && {
                                color: "#8fd571",
                              }),
                              ...(row.role_id === 2 && {
                                color: "#288500",
                              }),
                              ...(row.role_id === 3 && {
                                color: "#db8ae6",
                              }),
                            }}
                          >
                            {roleNames[row.role_id]}
                          </strong>
                        </TableCell>

                        <Fragment>
                          <TableCell>{row.cso_name}</TableCell>
                        </Fragment>

                        <TableCell>
                          {row.last_login && (
                            <Fragment>
                              {moment(new Date(row.last_login)).format(
                                "DD MMMM YYYY"
                              )}{" "}
                              (
                              {moment().diff(
                                moment(new Date(row.last_login)).format(
                                  "DD MMMM YYYY"
                                ),
                                "days"
                              )}{" "}
                              days ago)
                            </Fragment>
                          )}
                        </TableCell>

                        <TableCell align="right">
                          {row.id && (
                            <Fragment>
                              {(userFilter === 1 || userFilter === 2) && (
                                <Fragment>
                                  <a
                                    onClick={() => onClickApprove(confirm, row)}
                                  >
                                    <Icon color="primary">check</Icon>
                                  </a>{" "}
                                </Fragment>
                              )}
                              {userFilter === 0 && (
                                <a onClick={() => setCurrentDbUser(row)}>
                                  <Icon>edit</Icon>
                                </a>
                              )}{" "}
                              {userFilter === 2 && (
                                <a onClick={(e) => onClickDelete(confirm, row)}>
                                  <Icon color="secondary">delete</Icon>
                                </a>
                              )}{" "}
                              {(userFilter === 0 || userFilter === 1) && (
                                <a
                                  onClick={() =>
                                    onClickReject(
                                      confirm,
                                      row,
                                      userFilter === 0
                                    )
                                  }
                                >
                                  <Icon color="secondary">cancel</Icon>
                                </a>
                              )}
                            </Fragment>
                          )}
                          <Fragment>
                            <a
                              title="See detailed Info"
                              onClick={() =>
                                setCurrentDbUser({ ...row, _readonly: true })
                              }
                            >
                              <Icon color="primary">folder_shared</Icon>
                            </a>{" "}
                          </Fragment>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <AddDbUserBtn />
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: "#eee",
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },
});

const mapStateToProps = (state) => ({
  db_users: state.db_users.all,
  loading: state.db_users.loading,
  error: state.db_users.error,
  current: state.db_users.current,
  bypassIo: state.db_users.bypassIo,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDbUser: (id) => dispatch(deleteDbUser(id)),
  approveDbUser: (id) => dispatch(approveDbUser(id)),
  rejectDbUser: (id, noMail = false) => dispatch(rejectDbUser(id, noMail)),
  getDbUsers: () => dispatch(getDbUsers()),
  clearErrorDbUsers: () => dispatch(clearErrorDbUsers()),
  setCurrentDbUser: (db_user) => dispatch(setCurrentDbUser(db_user)),
  clearCurrentDbUser: () => dispatch(clearCurrentDbUser()),
  setBypassIoDbUsers: (bypass) => dispatch(setBypassIoDbUsers(bypass)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DbUsers);
