import React, { Fragment, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Paper,
  DialogActions,
  Button,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import axios from "axios";
import { setArticleCorrection } from "../actions/articles";
import { connect } from "react-redux";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";

function ArticleCorrectionForm({
  articleCorrection,
  setArticleCorrection,
  users,
  setArticleCorrectionAction,
  loading,
}) {
  const [correction, setCorrection] = useState({ explanation: "", types: [] });
  const [error, setError] = React.useState("");
  const correctionTypes = [
    { id: 1, name: "JUR(s)" },
    { id: 2, name: "TAG(s)" },
    { id: 3, name: "Nested Entries" },
    { id: 4, name: "Chopped Heading" },
    { id: 5, name: "Tag for Legal Development" },
    { id: 7, name: "Name of source still appearing on headline" },
    { id: 6, name: "Other" }
  ];

  const [user, setUser] = useState(null);
  const classes = useStyles();
  const handleClose = () => {
    setCorrection({ explanation: "", types: [] });
    setArticleCorrection(null);
  };

  useEffect(() => {
    if (articleCorrection && users && users.length)
      setUser(users.find((u) => u.id === articleCorrection.user_id));
  }, [users, articleCorrection]);

  useEffect(() => {
    console.log(correction);
  }, [correction]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!correction.explanation || correction.types.length === 0) {
      setError("Please complete all fields");
    } else
      setArticleCorrectionAction(articleCorrection, correction)
        .then((res) => {
          console.log(res);
          handleClose();
        })
        .catch((e) => console.log(e));
  };

  const onChange = (e) => {
    if (e.target.name === "type_id") {
      if (correction.types.indexOf(e.target.value) === -1) {
        setCorrection({
          ...correction,
          types: correction.types.concat([e.target.value]),
        });
      } else {
        setCorrection({
          ...correction,
          types: correction.types.filter((t) => t !== e.target.value),
        });
      }
    } else {
      setCorrection({
        ...correction,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={true}
      style={{ width: "40%", height: "50%", margin: "auto" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={articleCorrection}
    >
      <DialogContent dividers={true}>
        {" "}
        {error && (
          <ErrorSnack error={{ msg: error }} clearError={() => setError("")} />
        )}
        {user ? (
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={12}>
                <strong>Request to:</strong> {user.name}
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl} fullWidth>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Type of error
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      name="type_id"
                      value={correction.types}
                      onChange={onChange}
                      input={<Input />}
                      renderValue={(selected) =>
                        correctionTypes
                          .filter((r) => selected.includes(r.id))
                          .map((t) => t.name)
                          .join(", ")
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {correctionTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                          <Checkbox
                            checked={correction.types.indexOf(type.id) !== -1}
                          />
                          <ListItemText primary={type.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  multiline
                  rows={10}
                  margin="dense"
                  name="explanation"
                  label="Explanation"
                  onChange={onChange}
                  value={correction.explanation}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
        ) : (
          <>NO USER FOUND</>
        )}
      </DialogContent>

      <DialogActions>
        {" "}
        <Button
          disabled={loading}
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          CANCEL
        </Button>
        <Button
          disabled={loading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          REQUEST
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
  text: {
    paddingTop: "18px",
    textAlign: "center",
  },
}));

const mapStateToProps = (state) => ({ loading: state.articles.loading });

const mapDispatchToProps = (dispatch) => ({
  setArticleCorrectionAction: (art, article_correction) =>
    dispatch(setArticleCorrection(art, article_correction)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleCorrectionForm);
