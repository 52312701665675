const initialState = {
  all: [],
  inbox: [],
  dashboard: [],
  dashboard_q: null,

  bin: [],
  untracked_inbox: [],
  current: null,
  filtered: null,
  filter: {
    active: false,
    filters: { article_status: { id: 1, name: "Active" }, sou: "" },
    result: [],
  },
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
  deleted_index: 0,
};

const filter = (action, state, inbox, untracked = false) => {
  let result = inbox;
  Object.keys(action.payload).forEach((filter) => {
    switch (filter) {
      case "header":
        result = result
          .map((article, original_position) => {
            if (!action.payload["header"])
              return { ...article, original_position };
            let terms = action.payload["header"].split(";");
            for (let term of terms) {
              if (
                term &&
                term.trim().length &&
                ((article["translated_header"] &&
                  article["translated_header"]
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) !== -1) ||
                  (article["original_header"] &&
                    article["original_header"]
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) !== -1) ||
                  (article["original_description"] &&
                    article["original_description"]
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) !== -1))
              )
                return { ...article, original_position };
            }
            return null;
          })
          .filter((el) => el);

        break;
      case "sou":
        result = result
          .map((article, original_position) => {
            if (!article.source) return null;
            if (!action.payload.sou) return { ...article, original_position };
            if (
              (article.source.source_type_id == 1 &&
                action.payload.sou === "MOU") ||
              (article.source.source_type_id == 2 &&
                action.payload.sou === "CSO") ||
              ((article.source.source_type_id == 3 ||
                article.source.source_type_id == 8 ||
                article.source.source_type_id == 9 ||
                article.source.source_type_id == 10 ||
                article.source.source_type_id == 11) &&
                action.payload.sou === "GOV") ||
              ((article.source.source_type_id == 7 ||
                article.source.source_type_id == 14) &&
                action.payload.sou === "DOG") ||
              (article.source.source_type_id == 4 &&
                action.payload.sou === "IHR") ||
              ((article.source.source_type_id == 5 ||
                article.source.source_type_id == 6 ||
                article.source.source_type_id == 12 ||
                article.source.source_type_id == 13) &&
                action.payload.sou === "OTHER")
            )
              return { ...article, original_position };
            else return null;
          })
          .filter((el) => el);
        if (action.payload.sou === "OTHER") {
          result.sort(
            (a, b) => a.source.source_type_id - b.source.source_type_id
          );
        }
        break;
      case "name":
      case "url":
      case "translated_header":
      case "original_header":
        result = result.filter(
          (article) =>
            article[filter] &&
            article[filter]
              .toLowerCase()
              .indexOf(action.payload[filter].toLowerCase()) !== -1
        );
        break;
      case "article_type":
      case "article_status":
      case "jurisdiction":
        if (action.payload[filter])
          result = result.filter(
            (article) => article[filter + "_id"] === action.payload[filter].id
          );
        break;
      case "languages":
        if (action.payload.languages && action.payload.languages.length) {
          let newResult = [];
          action.payload.languages.forEach((lang) => {
            newResult = newResult.concat(
              result.filter((article) => article.detected_language === lang.id)
            );
          });
          result = newResult;
        }
        break;
      case "tlds":
        console.log(action.payload.tlds);
        if (action.payload.tlds && action.payload.tlds.length) {
          let newResult = [];
          action.payload.tlds.forEach((t) => {
            newResult = newResult.concat(
              result.filter(
                (article) => article.tld && t.id.indexOf(article.tld) !== -1
              )
            );
          });
          result = newResult;
        }
        break;
      case "tier":
        if (action.payload[filter]) {
          result = result.filter(
            (article) => article["tier"] === action.payload[filter]
          );
        }
        break;
    }
  });
  return { active: true, filters: action.payload, result };
};

const articles = (state = initialState, action) => {
  let article;
  let newInbox;
  let newMother;
  let newDashboard;
  let originalMother;
  let articles;
  let position;
  let posI;
  let posF;

  switch (action.type) {
    case "DELETE_ARTICLE":
      article = action.payload;

      if (article.articles) {
        let deleted_index = state.inbox
          .map((art) => art.id)
          .indexOf(action.payload.id);
        newInbox = state.inbox.filter(
          (article) => article.id !== action.payload.id
        );
        return {
          ...state,
          inbox: newInbox,
          deleted_index,
          filter: filter({ payload: state.filter.filters }, state, newInbox),
        };
      } else {
        let deleted_index = state.inbox
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);

        newInbox = state.inbox.map((art) => {
          if (art.id === article.parent_article_id) {
            return {
              ...art,
              articles: art.articles.filter(
                (article) => article.id !== action.payload.id
              ),
            };
          } else return art;
        });
        return {
          ...state,
          inbox: newInbox,
          deleted_index,
          filter: filter({ payload: state.filter.filters }, state, newInbox),
        };
      }
    case "DELETE_ARTICLE_DASHBOARD":
      article = action.payload;

      if (article.articles) {
        let deleted_index = state.dashboard
          .map((art) => art.id)
          .indexOf(action.payload.id);
        newInbox = state.dashboard.filter(
          (article) => article.id !== action.payload.id
        );
        return {
          ...state,
          dashboard: newInbox,
          deleted_index,
        };
      } else {
        let deleted_index = state.dashboard
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);

        newInbox = state.dashboard.map((art) => {
          if (art.id === article.parent_article_id) {
            return {
              ...art,
              articles: art.articles.filter(
                (article) => article.id !== action.payload.id
              ),
            };
          } else return art;
        });
        return {
          ...state,
          dashboard: newInbox,
          deleted_index,
        };
      }
    case "DELETE_ARTICLE_USI":
      article = action.payload;
      if (!article.parent_article_id) {
        let deleted_index = state.untracked_inbox
          .map((art) => art.id)
          .indexOf(action.payload.id);
        newInbox = state.untracked_inbox.map((article) =>
          article.id !== action.payload.id
            ? article
            : {
                ...article.articles[0],
                parent_article_id: null,
                articles: article.articles.slice(1).map((art) => ({
                  ...art,
                  parent_article_id: article.articles[0].id,
                })),
              }
        );
        return {
          ...state,
          //if it's parent, make first daughter parent and move all others to it
          untracked_inbox: newInbox,
          deleted_index,
          filter: filter({ payload: state.filter.filters }, state, newInbox),
        };
      } else {
        let deleted_index = state.untracked_inbox
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);
        newInbox = state.untracked_inbox.map((art) => {
          if (art.id === article.parent_article_id) {
            return {
              ...art,
              articles: art.articles.filter(
                (article) => article.id !== action.payload.id
              ),
            };
          } else return art;
        });
        return {
          ...state,
          untracked_inbox: newInbox,
          deleted_index,
          filter: filter({ payload: state.filter.filters }, state, newInbox),
        };
      }
    case "APPROVE_ARTICLE":
      article = action.payload;

      let deleted_index = state.inbox
        .map((art) => art.id)
        .indexOf(action.payload.id);
      return {
        ...state,
        inbox: state.inbox.filter(
          (article) => article.id !== action.payload.id
        ),
        ...(article.inbxHack
          ? {
              dashboard: state.dashboard.filter(
                (article) => article.id !== action.payload.id
              ),
            }
          : {}),
        deleted_index,
      };

    case "ASSIGN_TO_MOTHER_INBOX_DASHBOARD":
      let newInbox2 = state.inbox.filter(
        (article) => article.id !== action.payload.child
      );
      return {
        ...state,
        inbox: newInbox2,
        filter: filter({ payload: state.filter.filters }, state, newInbox2),
      };
    case "REMOVE_DASH_MOTHER":
      let newInbox3 = state.inbox.map((article) =>
        article.id !== action.payload.child
          ? article
          : {
              ...article,
              dash_parent_article_id: null,
              articles: article.articles.map((as) => ({
                ...as,
                dash_parent_article_id: null,
              })),
            }
      );
      return {
        ...state,
        inbox: newInbox3,
        filter: filter({ payload: state.filter.filters }, state, newInbox3),
      };

    case "REMOVE_JUR":
      newInbox = state.inbox.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            jurisdictions: article.jurisdictions.filter(
              (jur) => jur.jurisdiction_id !== action.payload.jur_id
            ),
          };
        } else return article;
      });
      return {
        ...state,
        inbox: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "REMOVE_JUR_DASHBOARD":
      newDashboard = state.dashboard.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            jurisdictions: article.jurisdictions.filter(
              (jur) => jur.jurisdiction_id !== action.payload.jur_id
            ),
          };
        } else return article;
      });
      return {
        ...state,
        dashboard: newDashboard,
      };

    case "UPDATE_JURS":
      newInbox = state.inbox.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            jurisdictions: action.payload.jurs.map((j) => ({
              id: j.id,
              jurisdiction_id: j.id,
              jurisdiction: j,
            })),
          };
        } else return article;
      });
      return {
        ...state,
        inbox: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "UPDATE_JURS_DASHBOARD":
      newDashboard = state.dashboard.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            jurisdictions: action.payload.jurs.map((j) => ({
              id: j.id,
              jurisdiction_id: j.id,
              jurisdiction: j,
            })),
          };
        } else return article;
      });
      return {
        ...state,
        dashboard: newDashboard,
      };

    case "REMOVE_TAG":
      let daughter = true;
      for (const art of state.inbox) {
        if (art.id === action.payload.article_id) {
          daughter = false;
        }
      }
      if (!daughter) {
        newInbox = state.inbox.map((article) => {
          if (article.id === action.payload.article_id) {
            let newArticle = {
              ...article,
              tags: article.tags.filter(
                (tag) => tag.tag_id !== action.payload.tag_id
              ),
            };
            return newArticle;
          } else return article;
        });
      } else {
        newInbox = state.inbox.map((article) => {
          for (const artc of article.articles) {
            if (artc.id === action.payload.article_id)
              return {
                ...article,
                articles: article.articles.map((art) => {
                  if (art.id === action.payload.article_id) {
                    return {
                      ...art,
                      tags: art.tags.filter(
                        (tag) => tag.tag_id !== action.payload.tag_id
                      ),
                    };
                  } else {
                    return art;
                  }
                }),
              };
          }

          return article;
        });
      }
      return {
        ...state,
        inbox: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "REMOVE_TAG_DASHBOARD":
      let daughterD = true;
      for (const art of state.dashboard) {
        if (art.id === action.payload.article_id) {
          daughterD = false;
        }
      }
      if (!daughterD) {
        newDashboard = state.dashboard.map((article) => {
          if (article.id === action.payload.article_id) {
            let newArticle = {
              ...article,
              tags: article.tags.filter(
                (tag) => tag.tag_id !== action.payload.tag_id
              ),
            };
            return newArticle;
          } else return article;
        });
      } else {
        newDashboard = state.dashboard.map((article) => {
          for (const artc of article.articles) {
            if (artc.id === action.payload.article_id)
              return {
                ...article,
                articles: article.articles.map((art) => {
                  if (art.id === action.payload.article_id) {
                    return {
                      ...art,
                      tags: art.tags.filter(
                        (tag) => tag.tag_id !== action.payload.tag_id
                      ),
                    };
                  } else {
                    return art;
                  }
                }),
              };
          }

          return article;
        });
      }
      return {
        ...state,
        dashboard: newDashboard,
      };

    case "UPDATE_TAGS":
      let daughterT = true;
      for (const art of state.inbox) {
        if (art.id === action.payload.article_id) {
          daughterT = false;
        }
      }
      if (!daughterT) {
        newInbox = state.inbox.map((article) => {
          if (article.id === action.payload.article_id) {
            return {
              ...article,
              tags: action.payload.tags.map((t) => ({
                id: t.id,
                tag_id: t.id,
                tag: t,
              })),
            };
          } else return article;
        });
      } else {
        newInbox = state.inbox.map((article) => {
          for (const artc of article.articles) {
            if (artc.id === action.payload.article_id)
              return {
                ...article,
                articles: article.articles.map((art) => {
                  if (art.id === action.payload.article_id) {
                    return {
                      ...art,
                      tags: action.payload.tags.map((t) => ({
                        id: t.id,
                        tag_id: t.id,
                        tag: t,
                      })),
                    };
                  } else {
                    return art;
                  }
                }),
              };
          }

          return article;
        });
      }
      return {
        ...state,
        inbox: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "UPDATE_TAGS_DASHBOARD":
      let daughterTD = true;
      for (const art of state.dashboard) {
        if (art.id === action.payload.article_id) {
          daughterTD = false;
        }
      }
      if (!daughterTD) {
        newDashboard = state.dashboard.map((article) => {
          if (article.id === action.payload.article_id) {
            return {
              ...article,
              tags: action.payload.tags.map((t) => ({
                id: t.id,
                tag_id: t.id,
                tag: t,
              })),
            };
          } else return article;
        });
      } else {
        newDashboard = state.dashboard.map((article) => {
          for (const artc of article.articles) {
            if (artc.id === action.payload.article_id)
              return {
                ...article,
                articles: article.articles.map((art) => {
                  if (art.id === action.payload.article_id) {
                    return {
                      ...art,
                      tags: action.payload.tags.map((t) => ({
                        id: t.id,
                        tag_id: t.id,
                        tag: t,
                      })),
                    };
                  } else {
                    return art;
                  }
                }),
              };
          }

          return article;
        });
      }
      return {
        ...state,
        dashboard: newDashboard,
      };

    case "BACK_TO_INBOX":
      article = action.payload;

      if (article.articles) {
        return {
          ...state,
          inbox: [
            ...state.inbox.slice(0, state.deleted_index),
            action.payload,
            ...state.inbox.slice(state.deleted_index),
          ],
          bin: state.bin.filter(
            //This is for the dashboard
            (article) => article.id !== action.payload.id
          ),
          ...(article.inbxHack
            ? {
                dashboard: [
                  ...state.dashboard.slice(0, state.deleted_index),
                  action.payload,
                  ...state.dashboard.slice(state.deleted_index),
                ],
              }
            : {
                dashboard: state.dashboard.filter(
                  //This is for the dashboard
                  (article) => article.id !== action.payload.id
                ),
              }),
        };
      } else {
        let deleted_index = state.inbox
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);
        return {
          ...state,
          inbox: state.inbox.map((art) => {
            if (art.id === article.parent_article_id) {
              return {
                ...art,
                articles: [
                  ...art.articles.slice(0, state.deleted_index),
                  action.payload,
                  ...art.articles.slice(state.deleted_index),
                ],
              };
            } else return art;
          }),
          deleted_index: 0,
        };
      }

    case "BACK_TO_DASHBOARD":
      article = action.payload;

      if (article.articles) {
        return {
          ...state,
          dashboard: [
            ...state.dashboard.slice(0, state.deleted_index),
            action.payload,
            ...state.dashboard.slice(state.deleted_index),
          ],
        };
      } else {
        let deleted_index = state.dashboard
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);
        return {
          ...state,
          dashboard: state.dashboard.map((art) => {
            if (art.id === article.parent_article_id) {
              return {
                ...art,
                articles: [
                  ...art.articles.slice(0, state.deleted_index),
                  action.payload,
                  ...art.articles.slice(state.deleted_index),
                ],
              };
            } else return art;
          }),
          deleted_index: 0,
        };
      }

    case "BACK_TO_USI":
      article = action.payload;

      if (article.articles) {
        return {
          ...state,
          untracked_inbox: [
            ...state.untracked_inbox.slice(0, state.deleted_index),
            action.payload,
            ...state.untracked_inbox.slice(state.deleted_index),
          ],
          dashboard: state.dashboard.filter(
            //This is for the dashboard
            (article) => article.id !== action.payload.id
          ),
        };
      } else {
        let deleted_index = state.untracked_inbox
          .find((art) => art.id === article.parent_article_id)
          .articles.map((art) => art.id)
          .indexOf(action.payload.id);
        return {
          ...state,
          untracked_inbox: state.untracked_inbox.map((art) => {
            if (art.id === article.parent_article_id) {
              return {
                ...art,
                articles: [
                  ...art.articles.slice(0, state.deleted_index),
                  action.payload,
                  ...art.articles.slice(state.deleted_index),
                ],
              };
            } else return art;
          }),
          deleted_index: 0,
        };
      }

    case "REMOVE_FROM_MOTHER":
      article = action.payload;
      console.log("saywaaa");
      console.log(article);
      console.log("sayweee");
      newInbox = state.inbox.map((art) => {
        if (art.id === article.old_parent_article_id) {
          return {
            ...art,
            articles: art.articles.filter(
              (article) => article.id !== action.payload.id
            ),
          };
        } else return art;
      });
      console.log(article.position);
      return {
        ...state,
        inbox: [
          ...newInbox.slice(0, article.position),
          { ...action.payload, articles: [], parent_article_id: null },
          ...newInbox.slice(article.position),
        ],
        filter: {
          ...state.filter,
          result: [
            ...state.filter.result.slice(0, article.position),
            { ...action.payload, articles: [], parent_article_id: null },
            ...state.filter.result.slice(article.position),
          ],
        },
      };

    case "REMOVE_FROM_MOTHER_DASHBOARD":
      article = action.payload;
      console.log("saywaaa");
      console.log(article);
      console.log("sayweee");

      newDashboard = state.dashboard.map((art) => {
        if (art.id === article.old_parent_article_id) {
          return {
            ...art,
            articles: art.articles.filter(
              (article) => article.id !== action.payload.id
            ),
          };
        } else return art;
      });

      return {
        ...state,
        dashboard: [
          ...newDashboard.slice(0, article.position),
          { ...action.payload, articles: [], parent_article_id: null },
          ...newDashboard.slice(article.position),
        ],
      };

    case "REMOVE_MULTIPLE_FROM_MOTHER":
      newMother = action.payload.mother;
      originalMother = state.inbox.find(
        (art) => art.id === newMother.parent_article_id
      );
      articles = action.payload.articles;
      position = action.payload.articles[0].position;

      articles = articles.filter((a) => a.id !== newMother.id);

      articles = articles.map((a) => ({
        ...a,
        parent_article_id: newMother.id,
      }));

      newInbox = state.inbox.map((art) => {
        if (art.id === newMother.parent_article_id) {
          return {
            ...art,
            articles: art.articles.filter(
              (a) =>
                !articles.find((aa) => aa.id === a.id || newMother.id === a.id)
            ),
          };
        } else return art;
      });

      posI = newInbox.findIndex((a) => a.id === originalMother.id);
      posF = state.filter.result.findIndex((a) => a.id === originalMother.id);

      return {
        ...state,
        inbox: [
          ...newInbox.slice(0, posI),
          {
            ...newMother,
            tier: originalMother.tier,
            articles: articles,
            parent_article_id: null,
          },
          ...newInbox.slice(posI),
        ],
        filter: {
          ...state.filter,
          result: [
            ...state.filter.result.slice(0, posF),
            {
              ...newMother,
              articles: articles,

              parent_article_id: null,
            },
            ...state.filter.result.slice(posF),
          ],
        },
      };

    case "REMOVE_MULTIPLE_FROM_MOTHER_DASHBOARD":
      newMother = action.payload.mother;
      originalMother = state.dashboard.find(
        (art) => art.id === newMother.parent_article_id
      );
      articles = action.payload.articles;
      position = action.payload.articles[0].position;

      articles = articles.filter((a) => a.id !== newMother.id);

      articles = articles.map((a) => ({
        ...a,
        parent_article_id: newMother.id,
      }));

      newDashboard = state.dashboard.map((art) => {
        if (art.id === newMother.parent_article_id) {
          return {
            ...art,
            articles: art.articles.filter(
              (a) =>
                !articles.find((aa) => aa.id === a.id || newMother.id === a.id)
            ),
          };
        } else return art;
      });

      posI = newDashboard.findIndex((a) => a.id === originalMother.id);
      posF = state.filter.result.findIndex((a) => a.id === originalMother.id);

      return {
        ...state,
        dashboard: [
          ...newDashboard.slice(0, posI),
          {
            ...newMother,
            tier: originalMother.tier,
            articles: articles,
            parent_article_id: null,
          },
          ...newDashboard.slice(posI),
        ],
      };

    case "CONVERT_TO_MOTHER":
      article = action.payload;
      newInbox = state.inbox.map((art) => {
        if (art.id === article.parent_article_id) {
          return {
            ...article,
            parent_article_id: null,
            tier: art.tier,
            articles: art.articles
              .filter((article) => article.id !== action.payload.id)
              .concat([{ ...art, articles: null }])
              .map((art) => ({
                ...art,
                parent_article_id: action.payload.id,
              })),
          };
        } else return art;
      });

      return {
        ...state,
        inbox: newInbox,
      };

    case "CONVERT_TO_MOTHER_DASHBOARD":
      article = action.payload;
      newDashboard = state.dashboard.map((art) => {
        if (art.id === article.parent_article_id) {
          return {
            ...article,
            parent_article_id: null,
            jurisdictions: art.jurisdictions,
            tags: art.tags,
            articles: art.articles
              .filter((article) => article.id !== action.payload.id)
              .concat([{ ...art, articles: null }])
              .map((art) => ({
                ...art,
                parent_article_id: action.payload.id,
              })),
          };
        } else return art;
      });

      return {
        ...state,
        dashboard: newDashboard,
      };

    case "REASSIGN_MOTHER":
      let childIds = action.payload.children.map((c) => c.id);
      let newChildren = [];
      let inboxTmp = state.inbox;

      for (let child of action.payload.children) {
        //remove from original mother
        if (child.parent_article_id) {
          inboxTmp = inboxTmp.map((article) => {
            if (article.id === child.parent_article_id) {
              return {
                ...article,
                articles: article.articles.filter((a) => a.id !== child.id),
              };
            } else return article;
          });
        } else {
          //remove from inbox
          inboxTmp = inboxTmp.filter((a) => a.id !== child.id);
        }
        //get grandchildren
        let newGrandChildren = [];
        if (child.articles) {
          newGrandChildren = child.articles.map((a) => ({
            ...a,
            parent_article_id: action.payload.mother.id,
          }));
        }
        delete child.articles;

        //create new children array
        child = { ...child, parent_article_id: action.payload.mother.id };
        newChildren.push(child);
        if (newGrandChildren.length)
          newChildren = newChildren.concat(newGrandChildren);
      }

      inboxTmp = inboxTmp.map((article) => {
        if (article.id === action.payload.mother.id) {
          if (newChildren.length)
            return {
              ...article,
              articles: article.articles.concat(newChildren),
            };
          else return article;
        } else return article;
      });

      /*let childIds = action.payload.children.map((c) => c.id);
      let inboxTmp = state.inbox.map((article) => {
        if (action.payload.children.indexOf(article.id) !== -1) {
          return { ...article, parent_article_id: action.payload.mother.id };
        } else if (article.id === action.payload.mother.id) {
          newMother = {
            ...article,
            articles: article.articles.concat(
              state.inbox.filter(
                (article) => childIds.indexOf(article.id) !== -1
              )
            )
          };
          return newMother;
        } else return article;
      });

      let newChilds = [];

      inboxTmp = inboxTmp.map((article) => {
        if (childIds.indexOf(article.id) !== -1) {
          newChilds = newChilds.concat(article.articles);
          delete article.articles;
          return {
            ...article,
            parent_article_id: action.payload.mother.id
          };
        }
        if (action.payload.children.indexOf(article.parent_article_id) !== -1) {
          delete article.articles;
          return {
            ...article,
            parent_article_id: action.payload.mother.id
          };
        } else return article;
      });
      newChilds = newChilds.map((a) => ({
        ...a,
        parent_article_id: action.payload.mother.id
      }));

      inboxTmp = inboxTmp.map((article) => {
        if (article.id === action.payload.mother.id) {
          return {
            ...article,
            articles: article.articles.concat(newChilds).map((article) => {
              if (childIds.indexOf(article.id) !== -1) {
                delete article.articles;
                return {
                  ...article,
                  parent_article_id: action.payload.mother.id
                };
              } else return article;
            })
          };
        } else return article;
      });
      inboxTmp = inboxTmp.filter(
        (article) => childIds.indexOf(article.id) === -1
      );
      //use case when children inside mother
      //Remove from mother
      action.payload.children.forEach((child) => {
        if (child.parent_article_id) {
          inboxTmp = inboxTmp.map((art) => {
            if (art.id === child.parent_article_id) {
              return {
                ...art,
                articles: art.articles.filter((a) => a.id !== child.id)
              };
            }
            if (art.id === action.payload.mother.id) {
              return {
                ...art,
                articles: art.articles.concat([child])
              };
            } else return art;
          });
        }
      });*/
      return {
        ...state,
        inbox: inboxTmp,
        filter: filter({ payload: state.filter.filters }, state, inboxTmp),
      };
    case "REASSIGN_MOTHER_DASHBOARD":
      /* let childIdsD = action.payload.children.map((c) => c.id);
      let dashboardTmp = state.dashboard.map((article) => {
        if (action.payload.children.indexOf(article.id) !== -1) {
          return { ...article, parent_article_id: action.payload.mother.id };
        } else if (article.id === action.payload.mother.id) {
          newMother = {
            ...article,
            articles: article.articles.concat(state.dashboard.filter((article) => childIdsD.indexOf(article.id) !== -1))
          };
          return newMother;
        } else return article;
      });

      let newChildsD = [];

      dashboardTmp = dashboardTmp.map((article) => {
        if (childIdsD.indexOf(article.id) !== -1) {
          newChildsD = newChildsD.concat(article.articles);
          delete article.articles;
          return {
            ...article,
            parent_article_id: action.payload.mother.id
          };
        }
        if (action.payload.children.indexOf(article.parent_article_id) !== -1) {
          delete article.articles;
          return {
            ...article,
            parent_article_id: action.payload.mother.id
          };
        } else return article;
      });

      newChildsD = newChildsD.map((a) => ({
        ...a,
        parent_article_id: action.payload.mother.id
      }));

      dashboardTmp = dashboardTmp.map((article) => {
        if (article.id === action.payload.mother.id) {
          return {
            ...article,
            articles: article.articles.concat(newChildsD).map((article) => {
              if (childIdsD.indexOf(article.id) !== -1) {
                delete article.articles;
                return {
                  ...article,
                  parent_article_id: action.payload.mother.id
                };
              } else return article;
            })
          };
        } else return article;
      });
      dashboardTmp = dashboardTmp.filter((article) => childIdsD.indexOf(article.id) === -1);
      //use case when children inside mother
      //Remove from mother
      action.payload.children.forEach((child) => {
        if (child.parent_article_id) {
          dashboardTmp = dashboardTmp.map((art) => {
            if (art.id === child.parent_article_id) {
              return {
                ...art,
                articles: art.articles.filter((a) => a.id !== child.id)
              };
            }
            if (art.id === action.payload.mother.id) {
              return {
                ...art,
                articles: art.articles.concat([child])
              };
            } else return art;
          });
        }
      });*/
      let newChildrenD = [];
      let dashboardTmp = state.dashboard;

      for (let child of action.payload.children) {
        //remove from original mother
        if (child.parent_article_id) {
          dashboardTmp = dashboardTmp.map((article) => {
            if (article.id === child.parent_article_id) {
              return {
                ...article,
                articles: article.articles.filter((a) => a.id !== child.id),
              };
            } else return article;
          });
        } else {
          //remove from dashboard
          dashboardTmp = dashboardTmp.filter((a) => a.id !== child.id);
        }
        //get grandchildren
        let newGrandChildrenD = [];
        if (child.articles) {
          newGrandChildrenD = child.articles.map((a) => ({
            ...a,
            parent_article_id: action.payload.mother.id,
          }));
        }
        delete child.articles;

        //create new children array
        child = { ...child, parent_article_id: action.payload.mother.id };
        newChildrenD.push(child);
        if (newGrandChildrenD.length)
          newChildrenD = newChildrenD.concat(newGrandChildrenD);
      }

      dashboardTmp = dashboardTmp.map((article) => {
        if (article.id === action.payload.mother.id) {
          if (newChildrenD.length)
            return {
              ...article,
              articles: article.articles.concat(newChildrenD),
            };
          else return article;
        } else return article;
      });
      return {
        ...state,
        dashboard: dashboardTmp,
      };
    case "UPDATE_ARTICLE":
      if (!action.payload.parent_article_id)
        return {
          ...state,
          all: state.all.map((article) =>
            article.id !== action.payload.id ? article : action.payload
          ),
          inbox: state.inbox.map((article) =>
            article.id !== action.payload.id ? article : action.payload
          ),
          dashboard: state.dashboard.map((article) =>
            article.id !== action.payload.id ? article : action.payload
          ),
          filter: {
            ...state.filter,
            result: state.all.map((article) =>
              article.id !== action.payload.id ? article : action.payload
            ),
          },
        };
      else {
        console.log(action.payload);
        return {
          ...state,
          all: state.all.map((article) =>
            article.id !== action.payload.parent_article_id
              ? article
              : {
                  ...article,
                  articles: article.articles.map((art) =>
                    art.id !== action.payload.id ? art : action.payload
                  ),
                }
          ),
          inbox: state.inbox.map((article) =>
            article.id !== action.payload.parent_article_id
              ? article
              : {
                  ...article,
                  articles: article.articles.map((art) =>
                    art.id !== action.payload.id ? art : action.payload
                  ),
                }
          ),
          dashboard: state.dashboard.map((article) =>
            article.id !== action.payload.parent_article_id
              ? article
              : {
                  ...article,
                  articles: article.articles.map((art) =>
                    art.id !== action.payload.id ? art : action.payload
                  ),
                }
          ),
          filter: {
            ...state.filter,
            result: state.all.map((article) =>
              article.id !== action.payload.parent_article_id
                ? article
                : {
                    ...article,
                    articles: article.articles.map((art) =>
                      art.id !== action.payload.id ? art : action.payload
                    ),
                  }
            ),
          },
        };
      }

    case "ADD_ARTICLE":
      return {
        ...state,
        all: [...state.all, action.payload],
        inbox: [...state.inbox, action.payload],
        dashboard: [...state.dashboard, action.payload],
        filter: {
          ...state.filter,
          result: [...state.all, action.payload],
        },
      };
    case "GET_ARTICLES":
      return { ...state, all: action.payload };
    case "GET_INBOX_ARTICLES":
      return {
        ...state,
        inbox: action.payload,
        //filter: filter({ payload: state.filter.filters }, state, action.payload)
      };
    case "GET_DASHBOARD_ARTICLES":
      return {
        ...state,
        dashboard: action.payload.mothers,
        dashboard_q: action.payload.all,
      };
    case "GET_BIN_ARTICLES":
      return {
        ...state,
        bin: action.payload,
        filter: filter(
          { payload: state.filter.filters },
          state,
          action.payload
        ),
      };

    case "GET_UNTRACKED_INBOX_ARTICLES":
      return {
        ...state,
        untracked_inbox: action.payload,
        filter: filter(
          { payload: state.filter.filters },
          state,
          action.payload,
          true
        ),
      };

    case "REMOVE_FROM_USI":
      let newUSI = state.untracked_inbox.filter(
        (a) => a.id !== action.payload && a.parent_article_id !== action.payload
      );
      return {
        ...state,
        untracked_inbox: newUSI,
        filter: filter({ payload: state.filter.filters }, state, newUSI, true),
      };
    case "CURRENT_ARTICLE":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_ARTICLE":
      return { ...state, current: null };
    case "SET_BYPASS_IO_ARTICLES":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_ARTICLE":
      return { ...state, open: action.payload };

    case "FILTER_ARTICLE":
      if (!action.payload) action.payload = state.filter.filters;

      return {
        ...state,
        filter: filter(action, state, state.inbox),
      };

    case "FILTER_ARTICLE_DASHBOARD":
      if (!action.payload) action.payload = state.filter.filters;

      return {
        ...state,
        filter: filter(action, state, state.dashboard),
      };

    case "FILTER_ARTICLE_USI":
      if (!action.payload) action.payload = state.filter.filters;

      return {
        ...state,
        filter: filter(action, state, state.untracked_inbox),
      };

    case "SET_ARTICLE_CORRECTION":
      newInbox = state.dashboard.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            article_correction_id: action.payload.article_correction_id,
          };
        } else return article;
      });

      return {
        ...state,
        dashboard: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "SET_POSSIBLE_LEGAL_DEVELOPMENT":
      newInbox = state.inbox.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            possible_legal_development:
              action.payload.possible_legal_development,
          };
        } else return article;
      });

      return {
        ...state,
        inbox: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "SET_POSSIBLE_LEGAL_DEVELOPMENT_DASHBOARD":
      newInbox = state.dashboard.map((article) => {
        if (article.id === action.payload.article_id) {
          return {
            ...article,
            possible_legal_development:
              action.payload.possible_legal_development,
          };
        } else return article;
      });

      return {
        ...state,
        dashboard: newInbox,
        filter: filter({ payload: state.filter.filters }, state, newInbox),
      };

    case "ADD_SOURCE_TO_ARTICLES":
      return {
        ...state,
      };

    case "ERROR_ARTICLES":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: "",
        };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_ARTICLES":
      return { ...state, error: null };
    case "LOADING_ARTICLES":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default articles;
