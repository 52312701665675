import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLanguages } from "../actions/related";
import {
  addDbUser,
  clearCurrentDbUser,
  clearErrorDbUsers,
  deleteDbUser,
  getDbUsers,
  setCurrentDbUser,
  setOpenDbUser,
  updateDbUser,
} from "../actions/db_users";
import { roleNames } from "../utils/functions";
import moment from "moment";

const emptyDbUser = {
  pronoun: "",
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  showPassword: false,
  admin: false,

  role_id: 1,
};

const DbUserForm = ({
  current,
  open,
  clearCurrentDbUser,
  updateDbUser,
  setOpenDbUser,
  addDbUser,
  loading,
  allLanguages,
  getLanguages,
}) => {
  const classes = useStyles();
  const [db_user, setDbUser] = useState(emptyDbUser);

  const {
    pronoun,
    firstname,
    lastname,
    email,
    password,
    showPassword,
    admin,
    disabled,
    blocked,

    role_id,
  } = db_user;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (!allLanguages || !allLanguages.length) getLanguages();
    if (current !== null) {
      setDbUser(current);
    } else {
      setDbUser(emptyDbUser);
    }
  }, [current, open]);

  function handleClose() {
    setOpenDbUser(false);
    clearCurrentDbUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addDbUser(db_user);
      } else {
        updateDbUser(db_user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setDbUser(emptyDbUser);
    clearCurrentDbUser();
  };

  const onChange = (e, newVal = null) => {
    let val;
    if (
      e.target.name === "admin" ||
      e.target.name === "disabled" ||
      e.target.name === "blocked"
    ) {
      setDbUser({ ...db_user, [e.target.name]: e.target.checked ? 1 : 0 });
    } else {
      val = e.target.value;
      setDbUser({ ...db_user, [e.target.name]: val });
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "40%", height: "45%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New ILGA World Database User"
            : current && !open && !current._readonly
            ? "Edit ILGA World Database User"
            : "ILGA World Database User"}
        </DialogTitle>
        {current && current._readonly ? (
          <DialogContent dividers={true}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <strong>Pronoun:</strong> {current.pronoun}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>First Name:</strong> {current.firstname}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Last Name:</strong> {current.lastname}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Email:</strong> {current.email}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Level:</strong> {roleNames[current.role_id]}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Organisation:</strong> {current.cso_name}
              </Grid>

              <Grid item xs={12} md={6}>
                <strong>Access Reason:</strong> {current.access_reason}
              </Grid>

              <Grid item xs={12} md={6}>
                <strong>ID Number:</strong> {current.id}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Number of Searches:</strong> {current.number_searches}
              </Grid>

              <Grid item xs={12} md={6}>
                <strong>Last Login:</strong>{" "}
                {current.last_login && (
                  <Fragment>
                    {moment(new Date(current.last_login)).format(
                      "DD MMMM YYYY hh:mm:ss"
                    )}{" "}
                    (
                    {moment().diff(
                      moment(new Date(current.last_login)).format(
                        "DD MMMM YYYY"
                      ),
                      "days"
                    )}{" "}
                    days ago)
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <strong>Account created:</strong>{" "}
                {moment(new Date(current.created_at)).format("DD MMMM YYYY")}
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent dividers={true}>
            <form onSubmit={onSubmit} autocomplete="off">
              <input
                autocomplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <input type="submit" style={{ display: "none" }} />
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    margin="dense"
                    name="pronoun"
                    label="Pronoun"
                    value={pronoun}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={5}>
                  <TextField
                    margin="dense"
                    name="firstname"
                    label="First Name"
                    value={firstname}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={5}>
                  <TextField
                    margin="dense"
                    name="lastname"
                    label="Last Name"
                    value={lastname}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                    autocomplete="off"
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="dense"
                    name="email"
                    label="E-mail"
                    autocomplete="off"
                    value={email}
                    onChange={onChange}
                    className={classes.textField}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>{" "}
                <Grid item xs={12} md={6}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  >
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      type={showPassword ? "text" : "password"}
                      margin="dense"
                      name="password"
                      id="password"
                      autocomplete="off"
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={(e) =>
                              setDbUser({
                                ...db_user,
                                showPassword: !db_user.showPassword,
                              })
                            }
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      className={classes.textField}
                      labelWidth={70}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={disabled}
                        onChange={onChange}
                        value="1"
                        name="disabled"
                        color="primary"
                      />
                    }
                    label="Disabled?"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={blocked}
                        onChange={onChange}
                        value="1"
                        name="blocked"
                        color="primary"
                      />
                    }
                    label="Blocked?"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Role
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      name="role_id"
                      value={role_id ? roleNames[role_id] : roleNames[0]}
                      onChange={onChange}
                      input={<Input />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {[1, 2, 3].map((role) => (
                        <MenuItem key={role} value={role}>
                          <Checkbox checked={role === role_id} />
                          <ListItemText primary={roleNames[role]} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        )}
        {current && current._readonly ? (
          <DialogActions className={classes.formFooter}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={classes.formFooter}>
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              Cancel
            </Button>
          </DialogActions>
        )}
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const mapStateToProps = (state) => ({
  db_users: state.db_users.all,
  loading: state.db_users.loading,
  error: state.db_users.error,
  current: state.db_users.current,
  open: state.db_users.open,
  allLanguages: state.related.languages,
});

const mapDispatchToProps = (dispatch) => ({
  deleteDbUser: (id) => dispatch(deleteDbUser(id)),
  getDbUsers: () => dispatch(getDbUsers()),
  clearErrorDbUsers: () => dispatch(clearErrorDbUsers()),
  setCurrentDbUser: (db_user) => dispatch(setCurrentDbUser(db_user)),
  setOpenDbUser: (should) => dispatch(setOpenDbUser(should)),
  clearCurrentDbUser: () => dispatch(clearCurrentDbUser()),
  addDbUser: (db_user) => dispatch(addDbUser(db_user)),
  updateDbUser: (db_user) => dispatch(updateDbUser(db_user)),
  getLanguages: () => dispatch(getLanguages()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DbUserForm);
