import React, { Fragment, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Paper, DialogActions, Button, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formHeader: {
    backgroundColor: "#002060",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

function AddJur(props) {
  const { article, jurisdictions, setArticleNewJur, updateJurs } = props;

  const [newJurs, setNewJurs] = useState(null);
  const [iniJurs, setIniJurs] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (article && jurisdictions && jurisdictions.length) {
      console.log(jurisdictions);
      setIniJurs(
        jurisdictions.filter((j) => {
          if (article.jurisdictions && article.jurisdictions.length)
            return article.jurisdictions.find(
              (jur) => jur.jurisdiction_id === j.id
            );
          else return j.id === article.source.jurisdiction_id;
        })
      );
      console.log(
        jurisdictions.filter((j) => {
          if (article.jurisdictions && article.jurisdictions.length)
            return article.jurisdictions.find(
              (jur) => jur.jurisdiction_id === j.id
            );
          else return j.id === article.source.jurisdiction_id;
        })
      );
    }
  }, [article, jurisdictions, newJurs]);

  const handleClose = () => {
    setArticleNewJur(null);
  };

  const onChange = (val) => {
    setNewJurs(val);
  };
  const handleSave = (article) => {
    if (newJurs) {
      updateJurs(article, newJurs);
    } else {
      updateJurs(article, iniJurs);
    }
    handleClose();
  };
  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      style={{ left: "20px" }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={article || false}
    >
      {article && jurisdictions && jurisdictions.length > 0 && (
        <Paper style={{ padding: "10px" }}>
          <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            options={jurisdictions}
            disableClearable={false}
            clearOnBlur={true}
            classes={{
              option: classes.option,
            }}
            multiple
            defaultValue={iniJurs}
            autoHighlight
            size="small"
            start
            filterOptions={createFilterOptions({
              matchFrom: "start",
            })}
            onChange={(e, newVal) => onChange(newVal)}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <React.Fragment>
                {true && (
                  <span>
                    <img
                      width="32px"
                      src={
                        "/img/country_flags/" + option.country_code + "@3x.png"
                      }
                    />
                  </span>
                )}{" "}
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Jurisdictions"
                variant="outlined"
                autoFocus
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Paper>
      )}
      <DialogActions>
        {" "}
        <Button onClick={handleClose} variant="contained" color="secondary">
          CLOSE
        </Button>{" "}
        <Button
          onClick={(e) => handleSave(article)}
          variant="contained"
          color="primary"
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddJur;
