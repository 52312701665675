import { ConfirmProvider } from "material-ui-confirm";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AlertLogs from "./admin/AlertLogs";
import SimilarityTest from "./admin/SimilarityTest";
import Bin from "./bin/Bin";
import Dashboard from "./dashboard/Dashboard";
import ExclusionRules from "./exclusion_rules/ExclusionRules";
import DeleteSegments from "./delete_segments/DeleteSegments";
import Dashbox from "./inbox/Dashbox";

import Inbox from "./inbox/Inbox";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import Sources from "./source/Sources";
import JurTags from "./tag_manager/JurTags";
import TagManager from "./tag_manager/TagManager";
import TierManager from "./tag_manager/TierManager";
import UntrackedInbox from "./untracked_inbox/UntrackedInbox";
import Home from "./user/Home";
import UserRoles from "./user/UserRoles";
import Users from "./user/Users";
import IwmUsers from "./iwm_user/IwmUsers";
import setAuthToken from "./utils/setAuthToken";
import DashChilds from "./inbox/DashChilds";
import DbUsers from "./db_user/DbUsers";

const App = (props) => {
  const { token, login, logout, userId, roles } = useAuth();

  //

  //useEffect(() => {console.log(location.pathname);}, [token]);

  let routes;

  if (token) {
    setAuthToken(token, logout);
  }

  if (token) {
    routes = (
      <Switch>
        <Route path="/user-roles" exact component={UserRoles} />
        <Route path="/users" exact component={Users} />
        <Route path="/iwm_users" exact component={IwmUsers} />
        <Route path="/db_users" exact component={DbUsers} />
        <Route path="/sources" exact component={Sources} />
        <Route path="/alert-logs" exact component={AlertLogs} />
        <Route path="/similarity-test" exact component={SimilarityTest} />
        <Route path="/inbox" exact component={Inbox} />
        <Route path="/usi" exact component={UntrackedInbox} />
        <Route path="/dash_childs" exact component={DashChilds} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/dashboard/:id" exact component={Dashboard} />
        <Route path="/dashbox" exact component={Dashbox} />
        <Route path="/bin" exact component={Bin} />
        <Route path="/tag_manager" exact component={TagManager} />
        <Route path="/tier_triggers" exact component={TierManager} />
        <Route path="/exclusion_rules" exact component={ExclusionRules} />
        <Route path="/delete_segments" exact component={DeleteSegments} />
        <Route path="/jur_tags" exact component={JurTags} />
        <Route path="/:say/hello" exact>
          <div>Hello!</div>
        </Route>
        <Route path="/" exact render={(props) => <Home {...props} isLoggedIn={token} />} />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" render={(props) => <Home {...props} isLoggedIn={token} />} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        roles: roles,
        login: login,
        logout: logout
      }}
    >
      <ConfirmProvider>
        <Router>
          {token && <MainNavigation logged_user={props.logged_user || {}} />}
          <main>{routes}</main>
        </Router>
      </ConfirmProvider>
    </AuthContext.Provider>
  );
};

export default App;
